import { Injectable } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { CommonService } from '../../../common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class CouponcodeService extends CommonService {

  constructor(http: HttpClient) {
    super('couponcode',http);
  }
}
